import React, {useState, useEffect} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Container, Paper } from '@material-ui/core';
import { Assets } from 'enums';
import { Alert, Modal, Button, Spinner, Table, } from 'react-bootstrap';
import { PageStatus } from 'enums';
import { Show } from 'Layout';
import * as _ from "lodash";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';
import {AuthAPI, RedemptionModeAPI} from "../../API";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from "react-redux";
import GridContainer from "../Grid/GridContainer";
import CardHeader from "../Card/CardHeader";
import moment from "moment/moment";

function PreScreen() {

  const { userId, role } = useSelector((state: any) => state.adminUser.adminUser);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    appBar: {
      marginBottom: theme.spacing(3),
    },
    content: {
      padding: theme.spacing(3),
      textAlign: 'left',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      width: '100%',
      maxWidth: '400px', // Adjust the maximum width as needed
    },
    grow: {
      flexGrow: 1,
    },
  }));

  const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
    QUESTION: 'QUESTION'
  };

  const [formType, setFormType] = useState(MODAL_TYPES.NONE);
  const [status, setStatus] = useState(PageStatus.None);
  const [error, setError] = useState(null);
  const [formState, setFormState] = useState({});
  const [preScreenApiData, setPreScreenApiData] = useState([]);
  const [pageSize, setpageSize] = useState(10);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalResponseData, setTotalResponseData] = useState('');
  const [totalResponsePages, setTotalResponsePages] = useState('');
  const [modalHeader, setModalHeader] = useState("Add");
  const [id, setId] = useState('');
  const [answersFields, setAnswersFields] = useState([]);

  const handleOpenCreateModal = () => { setFormType(MODAL_TYPES.CREATE); setModalHeader("Add"); setId(''); };

  const fetchList = async () => {
    setStatus(PageStatus.Loading);
    try {
      const responseData: any = await AuthAPI.getSurveyQuestions({});
      console.log("responseData = ", responseData);

      setPreScreenApiData(responseData);
      setTotalResponseData(responseData.totalCount);
      setTotalResponsePages(responseData.totalPage);

      setStatus(PageStatus.Loaded);
    } catch (err) {
      setStatus(PageStatus.Loaded);
      setError("Failed, Try again!");
    }
  };

  const onCloseModalFunc = () => { setFormType(MODAL_TYPES.NONE); setFormState({}); setError(null); setAnswersFields([]); setStatus(PageStatus.None); };

  const onFormSubmit = async (event) => {
    event.preventDefault();
    try {
      let submitingData = { ...formState, answer: answersFields };
      let apiRespo: any;
      console.log("payload = ", submitingData);

    //   if (id) {
    //     apiRespo = await AuthAPI.updatePreScreenQuestions({ id, ...formState });
    //   } else {
    //     apiRespo = await AuthAPI.addPreScreenQuestions(submitingData);
    //   }
    //   setStatus(PageStatus.Submitting);
    //   const responseData: any = apiRespo;
    //   setStatus(PageStatus.Submitted);
    //   setFormType(MODAL_TYPES.NONE);
    //   setFormState({});
    //   fetchList();
    } catch (err) {
      setError("Failed, Try again!");
      setStatus(PageStatus.Error);
    }
  }

  const onFormUpdate = async (event) => {
    event.preventDefault();
    try {
    } catch (err) {
      setError("Failed, Try again!");
      setStatus(PageStatus.Error);
    }
  }

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormState((prev) => ({
      ...prev, [name]: value
    }));
    if (name !== "question") {
      if (name == "questionType" && value == "Range") {
        setAnswersFields([{ "lable": "Minimum Value", "name": "minVal", "value": "" }, { "lable": "Maximum Value", "name": "maxVal", "value": "" }]);
      } else {
        setAnswersFields([{ "lable": value, "name": value, "value": "" }]);
      }
    }
  };

  const addMoreRow = (name, indx, type) => {
    if (type == "add") {
      if (!answersFields[indx].value) {
        alert("please enter answer.");
        return;
      }
      let newObj = { "lable": name, "name": name, "value": "" };
      setAnswersFields((prevVal) => [
        ...prevVal,
        newObj
      ]);
    } else {
      setAnswersFields((prevVal) => {
        let updatedAnswers = [...prevVal];
        let newArr = updatedAnswers.filter((e, i) => i !== indx);
        return newArr;
      });
    }
  }

  const setInputValueInArr = (lable, evnt, indx) => {
    setAnswersFields((prevVal) => {
      let updatedAnswers = [...prevVal];
      let newArr = updatedAnswers.map((e, i) => {
        if (i == indx) {
          return { ...e, value: evnt.target.value };
        } else {
          return e;
        }
      });
      return newArr;
    });
  };



  const updatePreScreenQuesion = (e, elem) => {
    e.stopPropagation();
    setId(elem.id);
    setFormType(MODAL_TYPES.CREATE);
    setModalHeader("Update");
    setFormState({ "question": elem.question, "answer": elem.answer });
  }

  // useEffect(() => {  fetchList(); }, [pageSize, currentPageNumber]);
  useEffect(() => { fetchList(); }, []);

  const handlePageSizeChange = (newValue: any) => { setpageSize(newValue); setCurrentPageNumber(1); setTotalResponsePages(''); };
  const handlePageNumberChange = (newValue: any) => { setCurrentPageNumber(newValue); };

  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Modal
          centered
          size="lg"
          backdrop="static"
          onHide={onCloseModalFunc}
          show={formType == MODAL_TYPES.CREATE}
          style={{ zIndex: 1201 }}
        >
          <Modal.Header closeButton>
            <Modal.Title>  {modalHeader} PreScreen Question </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
            <Show when={status === PageStatus.Loading}>
              <div className="d-flex justify-content-center w-100 p-5">
                <Spinner animation="border" variant="primary" />
              </div>
            </Show>
            <Alert variant="danger" show={status === PageStatus.Error}>  {error}  </Alert>
            <form onSubmit={onFormSubmit} >
              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="titleEng">  Question*  </label>
                  <input
                    className="form-control"
                    name="question"
                    onChange={handleChange}
                    value={formState.question || ""}
                    placeholder="Enter question"
                    required
                  />
                </div>
                <div className="col">
                  <label htmlFor="titleEng">  Question Field Type*  </label>
                  <select
                    className="form-control"
                    name="questionType"
                    onChange={handleChange}
                    value={formState.questionType || ""}
                    required
                  >
                    <option value="">Select type</option>
                    <option value="Range">Range</option>
                    <option value="Zone">Zone</option>
                    <option value="Zipcode">ZipCode</option>
                    <option value="Dropdown">Dropdown</option>
                    <option value="Radio">Radio</option>
                    <option value="Checkbox">CheckBox</option>
                    <option value="MultiSelectDropdown">MultiSelectDropdown</option>
                  </select>
                </div>
              </div>

              {answersFields && answersFields.map((ele, index) => (
                ele.name == "maxVal" || ele.name == "minVal" ? (<div className="row mt-2" key={`abcd${index}`}>
                  <div className="col">
                    <label htmlFor="body">{ele.lable}*</label>
                    <input
                      className="form-control"
                      name={ele.name}
                      onChange={(e) => setInputValueInArr(ele.lable, e, index)}
                      value={ele.value}
                      type='number'
                      placeholder={`Enter ${ele.lable.toLowerCase()}`}
                      required
                    />
                  </div>
                </div>) :
                  (
                    <div className="col p-0" key={`abcd${index}`}>
                      <label htmlFor="body">Answer {answersFields.length > 1 ? index + 1 : ""}*</label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="text"
                          value={ele.value}
                          name=""
                          id=""
                          onChange={(e) => setInputValueInArr(ele.lable, e, index)}
                          placeholder={`Enter answer ${answersFields.length > 1 ? index + 1 : ""}`}
                          required
                        />
                        {answersFields.length > 0 && index == answersFields.length-1 ? (
                        <em
                          onClick={(e) => addMoreRow(ele.lable, index, "add")}
                          className="fa fa-plus text-success fa-lg cursor-pointer input-group-text"
                          title="Add more"
                        />
                        ) : null}
                        {answersFields.length > 1 ? (
                          <em
                            onClick={() => addMoreRow(ele.lable, index, "dlt")}
                            className="fa fa-trash text-danger fa-lg cursor-pointer input-group-text"
                            title="Delete row"
                          />
                        ) : null}
                      </div>

                    </div>
                  )
              ))}

              <Alert variant="danger" show={status === PageStatus.Error} className="mt-2">
                {error}
              </Alert>

              <div className="d-flex align-items-center mt-2">
                <button
                  type="submit"
                  className="btn btn-primary mr-3"
                >
                  Submit
                </button>

                <Show when={status === PageStatus.Submitting}>
                  <Spinner animation="border" variant="primary" />
                </Show>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          size="lg"
          backdrop="static"
          onHide={onCloseModalFunc}
          show={formType == MODAL_TYPES.UPDATE}
          style={{ zIndex: 1201 }}
        >
          <Modal.Header closeButton>
            <Modal.Title> PreScreen Question </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
            <Show when={status === PageStatus.Loading}>
              <div className="d-flex justify-content-center w-100 p-5">
                <Spinner animation="border" variant="primary" />
              </div>
            </Show>
            <Alert variant="danger" show={status === PageStatus.Error}>  {error}  </Alert>
            <form onSubmit={onFormUpdate} >
              <div className="row mt-2">
                <div className="col">
                  <h5>This is the demo question. Get it ?</h5>
                  {/* <label htmlFor="titleEng">  Question*  </label>
                  <input
                    className="form-control"
                    name="question"
                    onChange={handleChange}
                    value={formState.question || ""}
                    placeholder="Enter question"
                    required
                  /> */}
                </div>
              </div>

              <Alert variant="danger" show={status === PageStatus.Error} className="mt-2">
                {error}
              </Alert>

              <div className="d-flex align-items-center mt-2">
                <button
                  type="submit"
                  className="btn btn-primary mr-3"
                >
                  Submit
                </button>

                <Show when={status === PageStatus.Submitting}>
                  <Spinner animation="border" variant="primary" />
                </Show>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <GridContainer>
          <div className='w-100 mb-3'>
            <CardHeader color="primary">
              <div className="d-flex align-items-center justify-content-between">
                <h4>PreScreen Questions</h4>
                <div>
                  <Button
                    onClick={handleOpenCreateModal}
                    variant="primary"
                    size="sm"
                    className="mx-1"
                  >
                    <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />
                    Create
                  </Button>
                </div>
              </div>
            </CardHeader>
          </div>
        </GridContainer>


        <div className="jumbotron bg-white p-3 border shadow-sm">
          <div className='mb-2 mt-3'>
            <ShowDataAccording2PageSize
              currentPageSize={pageSize}
              currentPage={currentPageNumber}
              sendPageSize={handlePageSizeChange}
              showTotalData={totalResponseData}
              sendLastPage={totalResponsePages}
            />
          </div>
          <div>
            <Table responsive size="sm" bordered>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Question</th>
                  <th>CreatedAt</th>
                  <th>UpdatedAt</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  preScreenApiData.map((preScreen, index) => (
                    <tr key={preScreen.id}>
                      {/* <td>{index + 1}</td> */}
                      <td>{((pageSize) * (currentPageNumber - 1)) + (index + 1)}</td>
                      <td>
                        <span
                          aria-label="button"
                          role="button"
                          tabIndex={0}
                          className="text-primary"
                          onKeyPress={() => null}
                          onClick={() => {
                            setFormType(MODAL_TYPES.UPDATE);
                          }}
                          dangerouslySetInnerHTML={{
                            __html: preScreen.question || 'Title',
                          }}
                        />
                      </td>
                      <td>{moment(preScreen.createdAt).format('MM/DD/YYYY HH:mm A')}</td>
                      <td>{moment(preScreen.updatedAt).format('MM/DD/YYYY HH:mm A')}</td>
                      <td>
                        {role && (role == "admin" || role == "sub-admin") ? (
                          <div className='d-flex text-center'>
                            <div>
                              <span
                                aria-label="button"
                                role="button"
                                tabIndex={0}
                              >
                                <i
                                  className='fa fa-edit text-success'
                                  onClick={(event) => updatePreScreenQuesion(event, preScreen)}
                                  title="Edit details"
                                ></i>
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  ))
                }
              </tbody>

            </Table>
            {/* {preScreenApiData.map((preScreen, index) => (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{preScreen.question} &nbsp;
                    {role && (role == "admin" || role == "sub-admin") ? (
                      <i
                        className='fa fa-edit text-success'
                        onClick={(event) => updatePreScreenQuesion(event, preScreen)}
                        title="Edit details"
                      ></i>
                    ) : null}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div dangerouslySetInnerHTML={{ __html: preScreen.answer }} />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))} */}
          </div>
          <div>
            {totalResponsePages && <PaginationNumbering
              currentPage={currentPageNumber}
              returnPagenumber={handlePageNumberChange}
              showTotalData={totalResponseData}
              sendLastPage={totalResponsePages}
            />}
          </div>
        </div>
      </div>
    </>
  );
}

export default PreScreen;
