import React, { Component } from 'react';
import {
  Alert, Button, Spinner, Table, Modal,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from './Form';
import { Details } from './Details';
import { PageStatus } from 'enums';
import {Show} from "../../Layout";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import moment from "moment/moment";
import {exportToExcel} from "../../Utils/ExportToExcel";
import {SamplesAPI} from "../../API";
import {SecQuestionsList} from "../Sec/SecQuestionsList";
import {SampleQuestionsList} from "./SampleQuestionsList";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';
import csvtojson from 'csvtojson';

const MODAL_TYPES = {
  NONE: 'NONE',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DETAILS: 'DETAILS',
  QUESTION: 'QUESTION',
  UPLOAD: 'UPLOAD' // Added UPLOAD modal type
};

type State = {
  status: PageStatus,
  error: string | null,
  formType: string,
  data: any[],
  id?: string | null,
  filteredData: any[],
  filters: {
    name: '',
  },
  pageSize : any,
  currentPageNumber : any,
  totalResponseData : any,
  totalResponsePages : any,
  loading: any,
  showUploadModal: boolean, // State for the upload modal visibility
  selectedFile: File | null, // State for the selected file
  jsonData: any[] | null, // Store JSON data in state
  samplename_newm : '', // Store sample name in state
  sampleDescription :'', // Store sample name in state

};

export class Samples extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      error: null,
      formType: MODAL_TYPES.NONE,
      data: [],
      id: null,
      filteredData: [],
      filters: {
        name: '',
      },
      pageSize : 10,
      currentPageNumber : 1,
      totalResponseData : '',
      totalResponsePages : '',
      loading: false,
      showUploadModal: false, // Initialize modal state
      selectedFile: null, // Initialize selected file
      jsonData: null, // Assuming you want to store this
      samplename_newm:  '',
      sampleDescription: '', // New state for sample description

    };
    this.fetchList = this.fetchList.bind(this);
  }
//=========================== Method to open the upload modal =================================
openUploadModal = () => {
  this.setState({ showUploadModal: true });
};
closeUploadModal = () => {
  this.setState({ showUploadModal: false, selectedFile: null, jsonData: null, samplename_newm:  '' });

};
handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  if (event.target.files && event.target.files.length > 0) {
    const file = event.target.files[0];
    
    // Check for valid CSV file type
    if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
      alert('Please upload a valid CSV file.');
      this.setState({ selectedFile: null }); // Reset selected file
      return; 
    }
    
    // Store the file in the state
    this.setState({ selectedFile: file });
  }
};
handleSampleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ samplename_newm: event.target.value });
};
handleSampleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
  this.setState({ sampleDescription: event.target.value });
};

convertCsvToJson = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const csvContent: string = reader.result ? String(reader.result) : ''; 

      // Check if CSV content is empty
      if (!csvContent.trim()) {
        reject(new Error('CSV content is empty.'));
        return;
      }

      // Convert CSV to JSON
      csvtojson()
        .fromString(csvContent)
        .then((jsonData) => {
          // Format the JSON data as per your requirement
          const formattedData = {
            sampleName: this.state.samplename_newm.trim() || '',
            sampleDescription: this.state.sampleDescription.trim() || '',
            details: jsonData.map(item => item.userId) // Adjust the key based on your CSV structure
          };
          this.setState({ jsonData: formattedData });
          resolve(formattedData);
        })
        .catch(reject);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsText(file);
  });
};

// Method to handle file upload when clicking the "Upload" button
handleUpload = async () => {
  const { selectedFile } = this.state;
  
  if (!selectedFile) {
    alert('Please select a file before uploading.');
    return;
  }
  
  try {
    const jsonData = await this.convertCsvToJson(selectedFile); 
    if(!jsonData.sampleName){
      alert('Please enter sample name first.');
      return;
    }
    if(!jsonData.sampleDescription){
      alert('Please enter sample description first.');
      return;
    }
    const apiResponse = await SamplesAPI.uploadFileUniqueIds(jsonData);
    console.log("Upload response:", apiResponse); 
    alert('File uploaded successfully!');
    this.fetchList();
    this.closeUploadModal();
  } catch (error) {
    console.error("Error uploading file:", error);
    alert("There was an error uploading the data.");
  }
};





  // =================================================================


  componentDidMount() {
    this.fetchList()
  }

  fetchList(): Promise<void> {
    const {pageSize, currentPageNumber, filters} = this.state; 
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => SamplesAPI.getAll(pageSize, currentPageNumber, filters))
      .then((countries) => {
        let responseData : any = countries;
        this.setState({ data: responseData.data, filteredData: responseData.data, totalResponseData: responseData.totalCount, totalResponsePages: responseData.totalPage,   status: PageStatus.Loaded });
      })
      .catch((error) => {
        this.setState({ error: error.message, status: PageStatus.Error });
      });
  }

  isShowDetailModal(): boolean {
    return this.state.formType === MODAL_TYPES.DETAILS
        || this.state.formType === MODAL_TYPES.DELETE;
  }


  handleFilterChange = (e) => {
    const { name, value } = e.target;
    this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            [name]: value,
          },
        }),
    );
  };

  applyFilters = () => {
    if (this.state.currentPageNumber == 1) { this.fetchList(); return; }
    this.setState({currentPageNumber : 1})

    // const { data } = this.state;
    // const { filters } = this.state;
    //   const filteredData = data.filter((user) => {
    //     return Object.keys(filters).every((key) => {
    //       if (filters[key] === '') return true;
    //       if (key === 'publishDate' || key === 'expiryDate') {
    //         return new Date(user[key]).toDateString() === new Date(filters[key]).toDateString();
    //       }
    //       return String(user[key]).toLowerCase().includes(String(filters[key]).toLowerCase());
    //     });
    //   });
    //   this.setState({filteredData});
    }


  clearFilter = async () => {
    await this.setState({ filters: {
        name: '',
      },
      pageSize : 10,
      currentPageNumber : 1,
      totalResponseData : '',
      totalResponsePages : ''
    })
    this.fetchList()
  }

  async handleExport(){
    // exportToExcel(this.state.filteredData, 'Samples');
    const {pageSize, currentPageNumber, filters} = this.state; 
    this.setState({ loading: true });
    try {
        const responseData = await SamplesAPI.getAll('All', currentPageNumber, filters);
        // const combinedData = [...rewardsData, ...this.state.filteredData];
        exportToExcel(responseData.data, 'Samples');
    } catch (error) {
        console.error("Error exporting data:", error);
    }finally {
        this.setState({ loading: false });
    }
  };

  async downloadThisSample(id){
    this.setState({ loading: true });
    try {
        const responseData : any = await SamplesAPI.getOneSampleUsersexport(id);
        exportToExcel(responseData.data.user, `${id}_Sample Data`);
    } catch (error) {
        console.error("Error exporting data:", error);
    }finally {
        this.setState({ loading: false });
    }
  }

  handlePageSizeChange = (newValue : any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }); };
  handlePageNumberChange = (newValue : any) => { this.setState({ currentPageNumber: newValue }); };

  componentDidUpdate(prevProps: {}, prevState: State) {
      if (prevState.pageSize !== this.state.pageSize || prevState.currentPageNumber !== this.state.currentPageNumber) { this.fetchList(); }
  };

  render() {
    // const { filteredData, filters } = this.state;
    const { filteredData, filters, pageSize, currentPageNumber, totalResponseData, totalResponsePages,showUploadModal, selectedFile, loading } = this.state;
    return (
        <>
        <GridContainer>
            <div className='w-100 mb-3'>
              <CardHeader color="primary">
                <div className="d-flex align-items-center justify-content-between">
                <h4>Samples</h4>
                
                
              
                <div>
                  {/* ====add functionalllity  here ONCLICK OPEN MODEL AND UPLOAD FILE ==== */}
                <Button
                    variant="info"
                    size="sm"
                    className="mx-1"
                    onClick={this.openUploadModal} // Open modal on button click
                  >
                    <FontAwesomeIcon icon={['fas', 'upload']} className="mr-2" />
                    Upload file
                  </Button>
                  {/* ========================================== */}
                  <Button
                      onClick={() => {
                        return this.setState({
                          formType: MODAL_TYPES.CREATE,
                        });
                      }}
                      variant="primary"
                      size="sm"
                      className="mx-1"
                  >
                    <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />
                    Create
                  </Button>
                </div>
                </div>
              </CardHeader>
            </div>
        </GridContainer>
{/* ========MODEL OPEN WILL BE HERE==== */}
<Modal show={this.state.showUploadModal} onHide={this.closeUploadModal}>
          <Modal.Header closeButton>
            <Modal.Title>Upload unique user ids and create a sample</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-2'>
              <label htmlFor="samplename">Sample Name*</label>
              <input type="text" id="samplename_newm" className="form-control"
                  value={this.state.samplename_newm} onChange={this.handleSampleNameChange} // Update state with sample name input
                />
            </div>
            <div className='mb-2'>
              <label htmlFor="samplename">Sample Discription*</label>
              <textarea id="sampleDescription" className="form-control"
                 value={this.state.sampleDescription} onChange={this.handleSampleDescriptionChange} // Update state with sample description input
          />
            </div>
            <div className='mb-2'>
              <label htmlFor="samplename">File userId*</label>
              <input  type="file" className="form-control" accept=".csv" onChange={this.handleFileChange}  />
              {this.state.selectedFile && (
              <div>
                <p><b>File selected Name: </b>{this.state.selectedFile.name}</p>
              </div>
            )}
            </div>
            
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeUploadModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleUpload}>
              Upload
            </Button> {/* This button triggers the upload */}
          </Modal.Footer>
        </Modal>
{/* =================================== */}
          <div className="jumbotron bg-white p-3 border shadow-sm">
            <div className='mb-3'>Filter</div>

            <form>
              <div className="row">
                <div className="col">
                  <label>Name</label>
                  <input type="text"
                         className="form-control"
                         placeholder="Enter..."
                         name="name"
                         value={filters.name}
                         onChange={this.handleFilterChange}
                  />
                </div>
              </div>
            </form>

            <div className="jumbotron bg-white p-1 mt-2 shadow-sm">
              <button type="button" className="btn btn-success" onClick={() => this.applyFilters()}>Filter Samples</button>
              <button type="button" className="btn btn-info ml-1" onClick={() => this.handleExport()}>Export</button>
              <button type="button" className="btn btn-danger ml-1" onClick={() => this.clearFilter()}>Clear Filter</button>
            </div>


          </div>

      <div className="jumbotron bg-white p-3 border shadow-sm">
        <Alert variant="danger" show={this.state.status === PageStatus.Error}>
          {this.state.error}
        </Alert>

        <Show when={this.state.status === PageStatus.Loading}>
          <div className="d-flex justify-content-center w-100 p-5">
            <Spinner animation="border" variant="primary" />
          </div>
        </Show>

        <Show when={this.state.status === PageStatus.Loaded}>
          <Show when={this.state.formType === MODAL_TYPES.CREATE}>
            <Form
              show={this.state.formType === MODAL_TYPES.CREATE}

              onClose={() => this.setState({
                formType: MODAL_TYPES.NONE,
              })}
              onSubmit={(id) => {
                this.fetchList();
                this.setState({
                  formType: MODAL_TYPES.DETAILS, id: id,
                });
              }}
            />
          </Show>

          <Show when={!this.state.filteredData.length}>
            <Alert variant="info" show={!this.state.filteredData.length}>
              At the current moment data is not available, Click button for add.
            </Alert>
          </Show>

          <Show when={!!this.state.filteredData.length}>
            <Show when={this.isShowDetailModal()}>
              <Details
                id={this.state.id}

                onClose={() => this.setState({
                  formType: MODAL_TYPES.NONE,
                  id: null,
                })}
                onUpdate={() => {
                  this.setState({
                    formType: MODAL_TYPES.UPDATE,
                  });
                }}
                onDelete={(id) => {
                  this.fetchList();
                  this.setState({
                    formType: MODAL_TYPES.NONE,
                    id: null,
                  });
                }}
              />
            </Show>

            <Show when={this.state.formType === MODAL_TYPES.UPDATE}>
              <Form
                show={this.state.formType === MODAL_TYPES.UPDATE}
                id={this.state.id}

                onClose={() => this.setState({
                  formType: MODAL_TYPES.NONE,
                  id: null,
                })}
                onSubmit={(id) => {
                  this.fetchList();
                  this.setState({
                    formType: MODAL_TYPES.DETAILS, id: id,
                  });
                }}
              />
            </Show>

            <Show when={this.state.formType === MODAL_TYPES.QUESTION}>
              <SampleQuestionsList
                  show={this.state.formType === MODAL_TYPES.QUESTION}
                  id={this.state.id}

                  onClose={() => this.setState({
                    formType: MODAL_TYPES.NONE,
                    id: null,
                  })}
                  onSubmit={(id) => {
                    this.fetchList();
                    this.setState({
                      formType: MODAL_TYPES.DETAILS, id: id,
                    });
                  }}
              />
            </Show>
            <div className='mb-2'>  
                <ShowDataAccording2PageSize 
                  currentPageSize={pageSize} 
                  currentPage={currentPageNumber}
                  sendPageSize={this.handlePageSizeChange}
                  showTotalData={totalResponseData} 
                  sendLastPage={totalResponsePages}
                />
            </div>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                {this.state.loading && (
                    <img style={{ height: "415px", }}
                        src="../loadernew.gif"
                        alt="Loading..."
                    />
                )}
            </div>
          <Table responsive size="sm" bordered>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Unique ID</th>
                <th>Name</th>
                <th>Profile Count</th>
                <th>Active</th>
                <th>CreatedAt</th>
                <th>UpdatedAt</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {
                this.state.filteredData.map((info, index) => (
                  <tr key={info.id}>
                    {/* <td>{index + 1}</td> */}
                    <td>{((pageSize)*(currentPageNumber-1))+(index + 1)}</td>
                    <td>{info.id}</td>
                    <td>
                      <span
                        aria-label="button"
                        role="button"
                        tabIndex={0}
                        className="text-primary"
                        onKeyPress={() => null}
                        onClick={() => {
                          this.setState({
                            formType: MODAL_TYPES.DETAILS,
                            id: info.id,
                          });
                        }}
                        dangerouslySetInnerHTML={{
                          __html: info.name || 'Title',
                        }}
                      />
                    </td>
                    <td>{info.profileCount}</td>
                    <td>{info.isActive === true ? 'Yes' : 'No' }</td>
                    <td>{moment(info.createdAt).format('MM/DD/YYYY HH:mm A')}</td>
                    <td>{moment(info.updatedAt).format('MM/DD/YYYY HH:mm A')}</td>
                    <td>
                      <div className='d-flex text-center'>
                        <div>
                          <span
                              aria-label="button"
                              role="button"
                              tabIndex={0}
                              className="text-primary"
                              onKeyPress={() => null}
                              onClick={() => {
                                this.setState({
                                  formType: MODAL_TYPES.QUESTION,
                                  id: info.id,
                                });
                              }}
                          >Questions</span>
                          <div>
                            <a href="javascript:;">
                              <i className='fa fa-download text-success align-item-center' onClick={()=>{this.downloadThisSample(info.id)}}></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>

          </Table>
          <div>
            <PaginationNumbering 
              currentPage={currentPageNumber} 
              returnPagenumber={this.handlePageNumberChange}
              showTotalData={totalResponseData} 
              sendLastPage={totalResponsePages}
            />
          </div>
        </Show>
        </Show>
      </div>
        </>
    );
  }
}
